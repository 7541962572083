import { ASSIGNEE_LABEL, LABEL, TEXT, URL_DETAIL_LINK } from '@moxie/constants';
import { AuthUser, IOfficeBranch, IUser } from '@shared-components/models';
import { Col, Row, Spin, Typography } from 'antd';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '@crm/core';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { UserCard } from '../../shared/user-card/user-card';
import { UnassignedCard } from '../contact/components/assignee-card/user-name-card';
import { DateFormat } from '@crm/shared';
import { CrmTable } from '@shared-components/elements';
import { ContactTableNameCard } from '../contact/components/contact-add-edit/contact-table-name-card';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';
import { getcontactList } from '@crm/services.api';
import { IContactCrm } from '@model/crm/contact.model';
import SelectBranch from '../contact/select-branch';

interface ContactColProps {
  currentUser: IUserData | (IUser & AuthUser) | null;
  triggerRefresh: () => void;
  setInitialData: React.SetStateAction<IContactCrm | undefined>;
  setIsDrawerOpen: React.SetStateAction<boolean>;
  setIsEditMode: React.SetStateAction<boolean>;
  canAccess: any;
}

interface IModifiedObj {
  [key: string]: string;
}

const { Text } = Typography;

const useColumns = ({ currentUser, triggerRefresh, setInitialData, setIsDrawerOpen, setIsEditMode, canAccess }: ContactColProps) => {
  const cols = [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internalId',
      key: 'internalId',
      render: (value: string, data: IContactCrm): ReactNode => {
        return (
          //   // TODO:: ADD COMPANY CODE
          <Link to={`${URL_DETAIL_LINK}${data?.id}/deals`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.companyCode ? data?.company?.companyCode + '-' + value : value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: () => 0,
      render: (_text: string, row: IContactCrm, index: number): ReactElement => (
        <ContactTableNameCard
          id={row.id}
          firstName={row.firstName || ''}
          email={row.email || ''}
          lastName={row.lastName}
          data={row}
          index={index.toString()}
          userProp={currentUser}
          triggerRefresh={triggerRefresh}
          setInitialData={setInitialData}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsEditMode={setIsEditMode}
          hideMenu={!canAccess('contact', 'update')}
        />
      ),
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      key: 'phone',
      render: (_text: string, row: IContactCrm): ReactNode => {
        const phoneNumber = row?.countryCode && row?.phone ? `${row?.countryCode} ${row?.phone}` : '-';
        return (
          <Row justify={phoneNumber === '-' ? 'center' : 'start'} className="lead-table__phone">
            <Col>
              {phoneNumber}
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.BRANCH,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__city">
            <span>{branch ? branch?.name : '-'}</span>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      dataIndex: 'assignee',
      key: 'assigneeId',
      width: 300,
      render: (text: string, data: IContactCrm): ReactElement => {
        return data?.assigneeId !== null ? (
          <UserCard
            firstName={data?.assignee?.firstName || ''}
            lastName={data?.assignee?.lastName || ''}
            email={data?.assignee?.email || ''}
            showEmailAsLink={true}
          />
        ) : (
          <UnassignedCard />
        )
      },
    },
    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: () => 0,
      render: (text: string) => {
        return <DateFormat date={text} />
      },
    },
  ];

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c as any))
  }, [])
}

const ClientTableList = ({
  branch,
  setBranch,
  refresh,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
}: any) => {
  const { name } = useParams<{ name: string; }>()

  const currentUser = useAppSelector(store => store.auth.user);
  const canAccess = useCanAccess();

  const modifiedObj: IModifiedObj = {
    "all-clients": "all-contacts",
    "my-clients": "my-contacts",
  }

  const filter = useMemo(() => {
    return { branch, status: 'Client' };
  }, [branch])

  const clientColumns = useColumns({ currentUser, triggerRefresh, setInitialData, setIsDrawerOpen, setIsEditMode, canAccess })

  if (!branch) {
    return (
      <div className="page-load">
        <Spin />
      </div>
    )
  }

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        key={name}
        tableName={TEXT.CLIENTS}
        filterContent={
          <SelectBranch
            handleBranchChange={setBranch}
            branch={branch}
          />
        }
        refresh={refresh}
        size={'small'}
        columns={clientColumns}
        type={modifiedObj[name] as string}
        filter={filter as Record<string, unknown>}
        action={getcontactList}
        className="lead-table"
      />
    </div>
  );
};

export { ClientTableList };
